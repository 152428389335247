<template>
  <div>
    <el-upload
      class="upload-demo"
      action="/"
      :on-change="handleChange"
      :on-remove="handleRemove"
      :auto-upload="false"
    >
      <el-button
        size="small"
        type="primary"
      >
        Click to upload
      </el-button>
    </el-upload>

    <hr>

    <LEDDLog
      v-if="form && form.displayType === 'ledd_log'"
      :read-only="isReadOnly"
      :form-version-id="form.id"
    />
    <LogForm
      v-else-if="form && (form.displayType === 'log' || form.displayType === 'medical_conditions')"
      :read-only="isReadOnly"
      :form-version-id="form.id"
      :visit="visit"
    />
    <PatientForm
      v-else-if="form"
      ref="PatientForm"
      :read-only="isReadOnly"
      :is-validating="false"
      :form="form"
      :visit="visit"
      :visit-start-date="visit.visitInstance.startDate"
    />
    <EmptyState
      v-else-if="!form"
      title="Form Not Found"
      image="form"
      :image-above-text="true"
    />
  </div>
</template>
<script>
import PatientForm from '@/components/PatientForm/PatientForm'
import LogForm from '@/components/LogForm/LogForm'
import LEDDLog from '@/components/LEDDLog/LEDDLog'
import EmptyState from '@/components/EmptyState/EmptyState'
import visit from './mockVisitInstance'
import baseForm from './mockComputedForm'

export default {
  name: 'CrfPreview',
  components: {
    PatientForm,
    LogForm,
    LEDDLog,
    EmptyState
  },
  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: null,
      visit
    }
  },
  methods: {
    handleRemove() {
      this.form = null
    },
    handleChange(file) {
      const _this = this
      const reader = new FileReader()
      reader.readAsText(file.raw)
      reader.onload = function() {
        try {
          const crf = JSON.parse(reader.result)
          baseForm.instance.formVersion = { ...baseForm.instance.formVersion, ...crf }
          _this.form = { ...baseForm, ...crf }
        } catch (ex) {
          throw new Error(ex)
        }
      }
      reader.onerror = function() {
        throw new Error(reader.error)
      }
    }
  }
}
</script>
