/**
 * You will need to pass valid participant Id as well as a valid visit instance id to get a CRF to render
 * Example:
 * /crf-preview/participant/fd072998-99d3-4248-ab0c-36dd6c6bcd13/visit/f7538c3b-df1f-433b-a817-f1945712c8e6
 **/

const baseForm = {
  'id': '2b4510e7-cbe6-400b-9dc0-e4f17abfbb76',
  'version': 0,
  'instance': {
    'id': 'b2198861-cb50-44b2-949a-a5f9533de714',
    'position': 1,
    'requiredForms': [],
    'status': 'not_started',
    'assessmentDate': (new Date()).toISOString().split('T')[0],
    'reason': null,
    'comment': null,
    'isOptional': false,
    'formVersion': {
      'id': '2b4510e7-cbe6-400b-9dc0-e4f17abfbb76',
      'version': 0
    }
  },
  '__typename': 'FormInstanceWithAnswers'
}

export default baseForm
